<template>
  <div class="box p-4 flex justify-between">
    <div class="flex flex-col font-bold justify-center">
      <span>Numero de radicado</span>
      <span class="text-2xl">{{ tutela.numRadicado }}</span>
    </div>
    <div class="flex flex-col justify-center gap-2">
      <div class="flex gap-2 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600 p-1 rounded-full mr-2" viewBox="0 0 20 20"
             fill="currentColor">
          <path fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"/>
        </svg>
        <span class="font-bold">Fecha de notificacion</span>
        <span>{{ tutela.fechaRecepcion }} {{ tutela.horaRecepcion }}</span>
      </div>
      <div class="flex gap-2 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600 p-1 rounded-full mr-2" viewBox="0 0 20 20"
             fill="currentColor">
          <path fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"/>
        </svg>
        <span class="font-bold">Fecha de vencimiento</span>
        <span>{{ tutela.fechaVencimiento }} {{ tutela.horaVencimiento }}</span>
      </div>
    </div>
  </div>

  <div v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" class="flex justify-end bg-white rounded-md p-2 mt-2">
    <div class="crear flex justify-end" style="min-width: 244px">
      <Button label="Crear una sanción" class="bg-blue-900" @click="$router.push({name:'pharmasan.administrativa.juridica.sanciones.crear', query: { idTutela: $route.params.id }})" />
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import storeTutela from '@/apps/pharmasan/juridica/procesosJuridicos/store/tutela.store'

export default {
  name: 'DesacatoHeader',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const goto = (name) => {
      router.push({ name })
    }
    const tutela = computed(() => {
      return storeTutela.getters.showTutela
    })
    onMounted(() => {
      storeTutela.dispatch('onShow', route.params.id)
    })
    return {
      goto,
      tutela
    }
  }
}
</script>

<style scoped>

</style>
